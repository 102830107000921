function loadImage(element) {
  element.style.opacity = 0;
  element.setAttribute('aria-busy', true);
  element.parentNode.setAttribute('aria-busy', true);
}

function resolveImage(element) {
  element.style.opacity = 1;
  element.setAttribute('aria-busy', false);
  element.parentNode.setAttribute('aria-busy', false);
}

function preloadBackgroundImage(source, element) {
  const image = new Image();
  const loadBg = () => {
    element.style.backgroundImage = `url(${image.src})`;
    element.setAttribute('aria-busy', false);
    element.setAttribute('bg-loaded', true);
    element.parentNode.setAttribute('bg-loaded', true);
  };

  image.src = source;
  image.addEventListener('load', loadBg);
  element.setAttribute('aria-busy', true);

  // Resolve instantly if image is cached
  if (image.complete) {
    loadBg();
  }
}

function initAllImages() {
  $('.RatioImage > img').each((index, element) => {
    loadImage(element);
    element.addEventListener('load', e => resolveImage(e.target));

    // Resolve instantly if image is cached
    if (element.complete) {
      resolveImage(element);
    }
  });

  $('.HeroContent-background').each((index, element) => {
    const src = element.getAttribute('bg-image');
    preloadBackgroundImage(src, element);
  });
}

initAllImages();
