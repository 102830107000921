const cache = {
  $target: $('.js-skipToNext'),
  $fixedHeader: $('#Header'),
};

const ANIMATION_SPEED_MS = 500;

function scrollDown(e) {
  const $container = $(e.currentTarget).parent();
  const headerOffset = cache.$fixedHeader.outerHeight() || 0;

  const bottom = ($container.offset().top + $container.outerHeight()) - headerOffset;
  $('body').animate({
    scrollTop: bottom,
  }, ANIMATION_SPEED_MS);
}

cache.$target.on('click', scrollDown);
