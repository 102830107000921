// import './lib/modernizr.custom';

import './includes/initialise';
import './includes/breakpoints';
import './includes/offcanvas';
import './includes/dropdowns';
import './includes/verticalMenu';
import './includes/heroContainer';
import './includes/scrollToBottom';
import './includes/fixedheader';
import './includes/imageLoader';

// --- No foundation javascript if we can help it
// import './lib/foundation';
// import './lib/foundationStickyFooter';
// import './lib/motion-ui';


// Test new behaviours here
$(() => {


  // JavaScript
window.sr = ScrollReveal();
sr.reveal('.ActionWrapper, .scrollAnimate, .form-wrapper', { duration: 700, distance: '10px', scale: 1, mobile: false, viewFactor: 0.20, });


});
