import debounce from 'lodash.debounce';

const cache = {
  $header: $('#Header'),
  $mainContent: $('#Site'),
};

let headerHeight;

function updateHeader() {
  cache.$mainContent.css({ 'padding-top': `${headerHeight}px` });
}

function fetchHeight() {
  return cache.$header.outerHeight();
}

headerHeight = fetchHeight();
updateHeader();

// Adjust top bar height when window size changes
window.addEventListener('resize', debounce(() => {
  headerHeight = fetchHeight();
  updateHeader();
}, 50));
