import { applyRight } from '../modules/utilities';

const selectors = {
  body: 'body',
  navigation: '#MainMenu',
  siteNavHasDropdown: '.menu-item-has-children',
  siteNavChildLinks: '.nav-drop-link',
  siteNavActiveDropdown: '.menu-item--active-dropdown',
  siteNavLinkMain: '.nav-main-link',
  siteNavLastLink: '.nav-drop-link--last',
  siteNavParentToggle: '.expand-sub-menu',
};

const config = {
  activeClass: 'menu-item--active-dropdown',
  childLinkClass: 'nav-drop-link',
};

let cache = {};

const delay = applyRight(setTimeout, 250);

function cacheSelectors() {
  const $nav = $(selectors.navigation);
  cache = {
    $nav: $nav,
    $topLevel: $nav.find(selectors.siteNavLinkMain),
    $dropdownToggle: $nav.find(selectors.siteNavParentToggle),
    $subMenuLinks: $nav.find(selectors.siteNavChildLinks),
    $activeDropdown: $nav.find(selectors.siteNavActiveDropdown),
    clickEvent: false,
  };
}

function hideDropdown($el) {
  // Early return if we have nothing to do
  if ($el.length === 0) return;

  // Create a collection with this element and decendants
  const $col = $el.find('.site-nav--has-dropdown').add($el);

  // Remove attributes and close dropdowns on element and decendants
  $col.each((i, el) => {
    const $this = $(el);
    $this.removeClass(config.activeClass);
    $this.children('.site-nav__link').attr('aria-expanded', 'false');
  });

  // reset active dropdown to parent
  cache.$activeDropdown = cache.$activeDropdown.parents(
    selectors.siteNavActiveDropdown,
  );

  // clean up handlers
  $(selectors.body).off('click.siteNav');
  $(window).off('keyup.siteNav');
}

function showDropdown($el) {
  $el.addClass(config.activeClass);
  $el.children('.site-nav__link').attr('aria-expanded', 'true');

  // Close sibling dropdowns
  hideDropdown($el.siblings(selectors.siteNavActiveDropdown));

  cache.$activeDropdown = $el;

  delay(() => {
    const $active = cache.$topLevel.parent(selectors.siteNavActiveDropdown);

    $(window).one('keyup.siteNav', evt => {
      if (evt.keyCode === 27) {
        hideDropdown($active);
      }
    });

    $(selectors.body).one('click.siteNav', () => {
      hideDropdown($active);
      cache.clickEvent = true;
      delay(() => {
        cache.clickEvent = false;
      });
    });
  });
}

function handleDropdownChange($el, evt) {
  // force stop the click from happening
  evt.preventDefault();
  evt.stopImmediatePropagation();

  // determine if the dropdown will open or close
  if (!$el.hasClass(config.activeClass)) {
    showDropdown($el);
  } else {
    hideDropdown($el);
  }
}

function init() {
  cacheSelectors();

  cache.$dropdownToggle.on('click.siteNav', evt => {
    const $el = $(evt.currentTarget).closest(selectors.siteNavHasDropdown);
    handleDropdownChange($el, evt);
    cache.clickEvent = true;
    delay(() => {
      cache.clickEvent = false;
    });
  });

  // check when we're leaving a dropdown and close the active dropdown
  $(selectors.siteNavLastLink).on('focusout.siteNav', () => {
    hideDropdown(cache.$activeDropdown);
  });

  // close dropdowns when on top level nav
  cache.$topLevel.on('focus.siteNav', () => {
    if (cache.$activeDropdown.length) {
      hideDropdown(cache.$activeDropdown);
    }
  });

  // Stop event from bubbling and being overriden by other click handlers,
  // returning link behaviour to normal for child links
  cache.$subMenuLinks.on('click.siteNav', evt => {
    evt.stopImmediatePropagation();
  });

  // Use hoverIntent for hoverable dropdowns on the top level
  $(selectors.siteNavHasDropdown).hoverIntent(evt => {
    // If a click event was just fired, skip this
    if (cache.clickEvent === false) {
      const $el = $(evt.currentTarget);
      handleDropdownChange($el, evt);
    }
  });
}

init();

$(document).on('menu-change', init);
