import Emoter from 'emoter';

(function($) {
  $(document).ready(() => {
    const panels = Emoter();
    const overlay = $('.Site-overlay');
    const $toggleButtonLeft = $('#PanelToggleLeft');
    const $toggleButtonRight = $('#PanelToggleRight');
    const $panelLeft = $('#PanelLeft');
    const $panelRight = $('#PanelRight');
    const $site = $(document.documentElement);
    const $header = $('.MobileHeader');

    /* Events */
    overlay.on('click', e => panels.state('position', 'closed'));
    $toggleButtonRight.on('click', () => {
      const newState = panels.state('position') === 'closed'
        ? 'right'
        : 'closed';
      panels.state('position', newState);
    });
    $toggleButtonLeft.on('click', () => {
      const newState = panels.state('position') === 'closed'
        ? 'left'
        : 'closed';
      panels.state('position', newState);
    });
    document.addEventListener('keyup', e => {
      if (e.keyCode === 27 && panels.state('position') !== 'closed') {
        panels.state('position', 'closed');
      }
    });

    /* State Actions */
    const transitions = {
      closed() {
        $panelLeft.attr('data-panelstate', 'closed');
        $panelRight.attr('data-panelstate', 'closed');
        $site.attr('data-translationstate', null);
        $header.attr('data-translationstate', null);
      },
      left() {
        $panelLeft.attr('data-panelstate', 'open');
        $panelRight.attr('data-panelstate', 'closed');
        $site.attr('data-translationstate', 'right');
        $header.attr('data-translationstate', 'right');
      },
      right() {
        $panelLeft.attr('data-panelstate', 'closed');
        $panelRight.attr('data-panelstate', 'open');
        $site.attr('data-translationstate', 'left');
        $header.attr('data-translationstate', 'left');
      },
    };

    /* Listeners */
    panels.on('position', () => {
      const pos = panels.state('position');
      if (transitions.hasOwnProperty(pos)) {
        transitions[pos]();
      }
    });

    /* Init */
    panels.load = function() {
      this.state('position', 'closed');
    };

    panels.load();
  });




$.scrollLock = ( function scrollLockClosure() {
    'use strict';

    var $html      = $( 'html' ),
        // State: unlocked by default
        locked     = false,
        // State: scroll to revert to
        prevScroll = {
            scrollLeft : $( window ).scrollLeft(),
            scrollTop  : $( window ).scrollTop()
        },
        // State: styles to revert to
        prevStyles = {},
        lockStyles = {
            'overflow-y' : 'scroll',
            'position'   : 'fixed',
            'width'      : '100%'
        };

    // Instantiate cache in case someone tries to unlock before locking
    saveStyles();

    // Save context's inline styles in cache
    function saveStyles() {
        var styleAttr = $html.attr( 'style' ),
            styleStrs = [],
            styleHash = {};

        if( !styleAttr ){
            return;
        }

        styleStrs = styleAttr.split( /;\s/ );

        $.each( styleStrs, function serializeStyleProp( styleString ){
            if( !styleString ) {
                return;
            }

            var keyValue = styleString.split( /\s:\s/ );

            if( keyValue.length < 2 ) {
                return;
            }

            styleHash[ keyValue[ 0 ] ] = keyValue[ 1 ];
        } );

        $.extend( prevStyles, styleHash );
    }

    function lock() {
        var appliedLock = {};

        // Duplicate execution will break DOM statefulness
        if( locked ) {
            return;
        }

        // Save scroll state...
        prevScroll = {
            scrollLeft : $( window ).scrollLeft(),
            scrollTop  : $( window ).scrollTop()
        };

        // ...and styles
        saveStyles();

        // Compose our applied CSS
        $.extend( appliedLock, lockStyles, {
            // And apply scroll state as styles
            'left' : - prevScroll.scrollLeft + 'px',
            'top'  : - prevScroll.scrollTop  + 'px'
        } );

        // Then lock styles...
        $html.css( appliedLock );

        // ...and scroll state
        $( window )
            .scrollLeft( 0 )
            .scrollTop( 0 );

        locked = true;
    }

    function unlock() {
        // Duplicate execution will break DOM statefulness
        if( !locked ) {
            return;
        }

        // Revert styles
        $html.attr( 'style', $( '<x>' ).css( prevStyles ).attr( 'style' ) || '' );

        // Revert scroll values
        $( window )
            .scrollLeft( prevScroll.scrollLeft )
            .scrollTop(  prevScroll.scrollTop );

        locked = false;
    }

    return function scrollLock( on ) {
        // If an argument is passed, lock or unlock depending on truthiness
        if( arguments.length ) {
            if( on ) {
                lock();
            }
            else {
                unlock();
            }
        }
        // Otherwise, toggle
        else {
            if( locked ){
                unlock();
            }
            else {
                lock();
            }
        }
    };
}() );



$('#PanelToggleRight, .Site-overlay').click(function() {
   $.scrollLock();
   //spin the hamburger
   $(".menu-icon-wrap").toggleClass("rotate");
});



})(jQuery);
