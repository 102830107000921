import debounce from 'lodash.debounce';

export default (function($) {
  window.theme = window.theme || {};

  function getBreakpoint() {
    const currentBreakpoint = window
      .getComputedStyle(document.body, ':before')
      .getPropertyValue('content')
      .replace(/"/g, '');
    window.theme.currentBreakpoint = currentBreakpoint;
    return currentBreakpoint;
  }

  let lastBreakpoint = getBreakpoint();

  $(window).resize(debounce(() => {
    const breakpoint = getBreakpoint();

    if (breakpoint !== lastBreakpoint) {
      lastBreakpoint = breakpoint;
      $(document).trigger('breakpoint', { breakpoint });
    }
  }, 100)).resize();

  return {
    getBreakpoint,
  };
})(jQuery);
