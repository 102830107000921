
const cache = {
  $heroes: $('.HeroContent-backgroundContainer'),
  $header: $('#Header'),
};

function correctHeight() {
  const headerHeight = cache.$header.outerHeight();
  cache.$heroes.css({ height: `calc(100vh - ${headerHeight}px)` });
}

$(document).on('breakpoint', correctHeight);

correctHeight();
