// Invoke the originally-specified function, passing in all originally-
// specified arguments, followed by any just-specified arguments.
export function apply(fn, ...args) {
  return (...rArgs) => fn.apply(this, [...args, ...rArgs]);
}

// Invoke the originally-specified function, passing in all just-
// specified arguments, followed by any originally-specified arguments.
export function applyRight(fn, ...args) {
  return (...lArgs) => fn.apply(this, [...lArgs, ...args]);
}
